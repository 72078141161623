<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('templates.editOddsTemplate') }}</h5>
                <div class="p-field p-grid">
                    <label for="sportId" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="text" v-model="templateObj.name" />
                    </div>
                    <div>
                        <Button @click="addInterval()" :label="$t('templates.addInterval')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-text-bold">
                    <div class="p-col-4 p-md-2">{{ $t('templates.from') }}</div>
                    <div class="p-col-4 p-md-2">{{ $t('templates.to') }}</div>
                    <div class="p-col-4 p-md-2">{{ $t('templates.multiplier') }}</div>
                </div>
                <div class="p-col-12 p-grid" v-for="(interval, index) in templateObj.intervals" :key="'interval' + index">
                    <div class="p-col-3 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="text" v-model="interval.fromValue" />
                    </div>
                    <div class="p-col-3 p-md-2"><InputText style="width: 100%" id="sportId" type="text" v-model="interval.toValue" /></div>
                    <div class="p-col-3 p-md-2"><InputText style="width: 100%" id="sportId" type="text" v-model="interval.multiplier" /></div>
                    <div class="p-col-3 p-md-2"><Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="removeInterval(index)" /></div>
                </div>
                <div class="p-col-12">
                    <transition-group name="p-message" tag="div" class="p-col-12 p-md-6">
                        <Message :key="0" :severity="'info'">{{ $t('templates.maxValueInfoText') }}</Message>
                    </transition-group>
                </div>
                <div class="p-col-12">
                    <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            errorText: '',
            disableSubmit: false,
            SportsSettingsApi: new SportsSettingsApi(),
            showError: false,
            backRoute: 'oddsTemplates',
            templateObj: { _id: '', name: '', intervals: [] },
        };
    },
    mounted() {
        if (this.$route.params.id !== '0') {
            this.SportsSettingsApi.getOddsTemplate(this.$route.params.id)
                .then((response) => {
                    this.templateObj = response.data;
                })
                .catch((error) => {
                    this.errorText = error;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        }
    },
    methods: {
        submitForm: function () {
            this.disableSubmit = true;
            this.SportsSettingsApi.updateOddsTemplate(this.templateObj)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.disableSubmit = false;
                    this.errorText = error;
                    this.showError = true;
                });
        },
        addInterval() {
            var interval = {
                fromValue: 0,
                toValue: 0,
                multiplier: 0,
            };
            this.templateObj.intervals.push(interval);
        },
        removeInterval(index) {
            this.templateObj.intervals.splice(index, 1);
        },
    },
};
</script>